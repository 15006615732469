/* Misc */
html, body {
    overflow-x:hidden;
}

section {
    margin-bottom: 6em;
}
.descriptionText {
    display: block;
    float: left;
    width: 100%;
    margin-left: 0.5em;
}
section.pageHeaderImage .row {
    background: none;
}


/* Path Options */
.pathsList h1 {
    margin: auto auto .5rem -1.15em;
    padding-left: 0em;
    font-size: 2.5em;
}
span.descriptionText.pathDescriptionHP {
    margin-left: 0em;
}
.pathButtons img {
    max-width: 100%;
}
.pathButtons a {
    max-width: 100%;
    position: relative;
    display: block;
}
.pathButtons h5 {
    color: white;
    position: absolute;
    top: calc( 50% - 0.8em);
    width: 100%;
    text-align: center;
}
.pathButtons span {
    color: white;
    position: absolute;
    top: calc( 50% + .5em);
    font-size: .8em;
    font-style: italic;
    width: 100%;
    text-align: center;
    z-index: 1;
    left: 0;
}

.row.pathButtons {
    padding-top: 1.5em;
    padding-bottom: 2em;
}
.col-sm-4{
    padding-right: .5em;
    padding-left: .5em;
}

.col-sm-12{
    padding-right: .5em;
    padding-left: .5em;
}

/* CTA Buttons */
a.callToActionButton {
    background: #ed0744;
    padding: 1em 3em;
    color: white;
    font-weight: 600;
    letter-spacing: 0.05em;
    margin: auto;
    text-align: center;
    text-transform: uppercase;
}
.col-sm-6 a:hover {
    color: #FFFFFF;
}

/* Mission */
.col-sm-6.hpMission {
    left: -6em;
        min-height: 400px;
}
section.ourMission {
    margin-bottom: 0;
    min-height: 400px;
}
h1.blueBar.right {
    padding-left: 0em;
    font-size: 2.5em;
}

/* Events */
.events h1.blueBar.right {
    color: white;
}
span.descriptionText.eventDescriptionHP {
    margin-left: 0em;
    color: white;
}
.eventTile {
    min-height: fit-content;
    width: calc(30% - 1em);
    display: inline-block;
    overflow: hidden;
    margin: 1em 1em 1em 0em;
    text-align: left;
}
.eventTile img {
    max-width: 100%;
    min-width: 100%;
    max-height: 10em;
    height: auto;
}
.eventTile span {
    bottom: 0;
    color: #003449;
    font-size: .8em;
    line-height: 0em;
    font-style: italic;
}
.eventTile h1 {
    text-transform: uppercase;
    font-size: 1.25em;
    padding-left: 0;
    padding-top: 0.3em;
    margin-top: 0;
    margin-bottom: 0em;
    color: white;
    line-height: 1em;
}
.eventsContainer .eventTile {
    width: calc(50% - 1.19em);
    display: inline-grid;
}
.events a.callToActionButton {
    margin-top: 2em; 
    margin-bottom: 4em;
    display: block;
    width: fit-content;
    padding-left: 3em;
    padding-right: 3em;
}

.eventNavHandles {
    display: none !important;
}


/* Courses Container */
h1.coursesHeaderHP {
    padding-left: .15em;
    font-size: 2.5em;
}
.coursesThumbnailContainer {
    padding-top: 1.5em;
    padding-bottom: 3em;
}
.courseTile a {
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    position: relative;
}
.coursesThumbnailContainer {
    width: 100%;
    overflow: hidden;
}
.coursesThumbnailContainer .courseTile {
    width: calc(25% - 1em);
    display: inline-block;
    overflow: hidden;
    float: left;
    margin: 0.5em;
    margin-bottom: 1em;
}
.courseTile h3 {
    text-transform: uppercase;
    color: black;
    font-size: 1.25em;
    padding-top: .5em;
    line-height: 1em;
}
span.author {
    color: #2fae9f;
    text-transform: uppercase;
    display: block;
    font-size: .8em;
    line-height: .4em;
    font-style: italic;
}
.courseTile img {
    width: 100%;
}
span.courseTag {
    display: block;
    margin-top: 1em;
    width: fit-content;
    float: left;
    font-size: .75em;
}

/* FAQ Preview */
h1.redBar {
    font-size: 2.5em;
    padding-left: .2em;
}
.faqThumbnailContainer {
    width: 100%;
    height: fit-content;
    padding-top: 1.5em;
    padding-bottom: 3em;
}
section.faqList {
    text-align: left;
}
.faqTile {
    width: calc(25% - 1em);
    display: inline-block;
    overflow: hidden;
    margin: 0.5em;
    text-align: left;
    float: left;
}
.faqTile img {
    max-width: 100%;
    min-width: 100%;
    max-height: 10em;
    height: auto;
}
.faqTile span {
    bottom: 0;
    color: grey;
    text-transform: uppercase;
    font-size: .8em;
    line-height: .4em;
    font-style: italic;
    display: none;
}
.faqTile h3 {
    text-transform: uppercase;
    color: black;
    font-size: 1.25em;
    padding-left: 0;
    padding-top: 0.5em;
    line-height: 1em;
    margin-bottom: 0rem;
}
.faqTile.column {
    width: 100%;
}

.ourMission a.callToActionButton {
    display: block;
    float: left;
    margin-top: 1em;
}


/*HIDE EVENTS*/
section.events {
    display: none;
}